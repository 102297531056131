<template>
  <div>
    <div>
      <el-form :inline="true">
        <el-form-item>
          <el-select
            multiple
            v-model="searchParams.orgIds"
            :clearable="true"
            @change="SearchPageData"
            placeholder="请选择园区"
            class="centerSelInput"
          >
            <el-option
              v-for="item in ParkOption"
              :key="item.val"
              :label="item.text"
              :value="item.val"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-cascader
            clearable
            ref="cascaderVehicleTypes"
            placeholder="请选择车辆类型"
            class="centerSelInput centerInput"
            v-model="searchParams.vehicleTypeIds"
            :options="CarTypeOption"
            :props="CarTypeOptionParams"
            :show-all-levels="false"
            @change="handleVehicleTypes"
          ></el-cascader>
        </el-form-item>

        <el-form-item>
          <el-input
            :clearable="true"
            @clear="SearchPageData"
            v-model="searchParams.vehicleId"
            placeholder="请输入车辆编号"
            class="centerInput"
          ></el-input>
        </el-form-item>

        <el-form-item>
          <el-date-picker
            v-model="searchParams.dateRange"
            :clearable="true"
            @change="SearchPageData"
            :picker-options="pickerOptions"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            class="centerDatepicker"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item>
          <button class="centerCzbtn" @click="SearchPageData">搜索</button>
        </el-form-item>
      </el-form>
    </div>

    <Table
      :data1="historyListConfig.list"
      :columns1="historyListConfig.columns"
      :total="historyListConfig.total"
      :loading="historyListConfig.loading"
      :destroy-on-close="true"
      @getAjax="GetHistoryListData"
    />

    <el-dialog
      title="查看车辆位置"
      :visible.sync="historyDetailConfig.visible"
      width="60%"
    >
      <div id="lookContainer"></div>
      <span slot="footer" class="dialog-footer">
        <button class="centerBtn" @click="historyDetailConfig.visible = false">
          确 定
        </button>
        <button class="centerBtn resetbtn" @click="historyDetailConfig.visible = false">
          取 消
        </button>
      </span>
    </el-dialog>

    <!-- <el-drawer
			:title="historyDetailConfig.title"
			:visible.sync="historyDetailConfig.visible"
			:size="historyDetailConfig.size"
			:destroy-on-close="true"
		>
			
			<el-row :gutter="20" style="margin-top: 20px;">
				<el-col :span="14">
					<div class="map-container">
						<amap ref="gaodeMap" class="gaode-map">
						</amap>
						
						<div class="play-control-container" v-if="historyCarMovePlayControl.show">
							<div class="mask"></div>
							<div class="play-control">
								<div class="play">
									<i class="el-icon-video-play" @click="PlayCarMove" v-if="historyCarMovePlayControl.play === false"></i>
									<i class="el-icon-video-pause" @click="PauseCarMove" v-else></i>
								</div>
								<div class="speed">
									<el-dropdown trigger="click" placement="top" @command="SetCarMoveSpeed">
										<el-button type="text" class="btn-speed">{{ historyCarMovePlayControl.speedButtonText }}</el-button>
										<el-dropdown-menu slot="dropdown">
											<el-dropdown-item command="16">x16</el-dropdown-item>
											<el-dropdown-item command="8">x8</el-dropdown-item>
											<el-dropdown-item command="4">x4</el-dropdown-item>
											<el-dropdown-item command="2">x2</el-dropdown-item>
											<el-dropdown-item command="1">正常</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
							</div>
						</div>
					</div>
				</el-col>
				<el-col :span="10">
					<el-card class="track-list-container">
						<p class="list-container-title">轨迹列表</p>
						
						<ul class="list-container-body" v-if="historyDetailData.trackList.length > 0">
							<li v-for="(item, idx) in historyDetailData.trackList" :key="idx">
								<div style="overflow: hidden;" @click="SelectedTrack(idx)">
									<div class="icon">
										<i class="el-icon-caret-right"></i>
									</div>
									<div class="info">
										<p class="track-title">{{ item.trackName }}</p>
										<p class="track-time">
											<span>{{ item.trackDate }}</span>
											<span>时长: {{ item.duration }}</span>
										</p>
									</div>
								</div>
							</li>
						</ul>
						
						<el-empty :image="emptyImage" :image-size="140" v-else></el-empty>
					</el-card>
				</el-col>
			</el-row>
			
			<el-row :gutter="20" style="margin-top: 20px;">
				<el-col :span="12">
					<el-tabs type="border-card">
						<el-tab-pane label="车辆数据">
							<div class="chart-list-container" v-if="historyDetailData.carDataChart.length > 0">
								<div class="chart-item" v-for="(item, idx) in historyDetailData.carDataChart" :key="idx">
									<div class="chart" :id="'CarDataChart_' + idx"></div>
								</div>
							</div>
							
							<el-empty :image="emptyImage" :image-size="100" v-else></el-empty>
						</el-tab-pane>
					</el-tabs>
				</el-col>
				<el-col :span="12">
					<el-tabs type="border-card">
						<el-tab-pane label="车辆事件列表">
							<Table
								:data1="historyDetailData.carEvent.list"
								:columns1="historyDetailData.carEvent.columns"
								:total="historyDetailData.carEvent.total"
								:loading="historyDetailData.carEvent.loading"
								:destroy-on-close="true"
								@getAjax="GetCarEventData"
								v-if="historyDetailData.carEvent.list.length > 0"
							/>
							<el-empty :image="emptyImage" :image-size="100" v-else></el-empty>
						</el-tab-pane>
						<el-tab-pane label="车辆告警列表">
							<Table
								:data1="historyDetailData.carWarning.list"
								:columns1="historyDetailData.carWarning.columns"
								:total="historyDetailData.carWarning.total"
								:loading="historyDetailData.carWarning.loading"
								:destroy-on-close="true"
								v-if="historyDetailData.carWarning.list.length > 0"
							/>
							<el-empty :image="emptyImage" :image-size="100" v-else></el-empty>
						</el-tab-pane>
					</el-tabs>
				</el-col>
			</el-row>
			
			<div style="width: 100%; height: 30px;"></div>
		</el-drawer> -->
  </div>
</template>

<script>
import Table from "../../components/Table.vue";
import { _CarTypeOption, _ParkOption, _getParkList } from "../../api/global";

import {
  _FindCarHistoryDetail,
  _FindCarHistoryList,
} from "../../api/dataAnalysis/history";
import {
  _berakDetail,
  _DownloadEmergencyBrakeRecord,
  _downloadEmergencyVideo,
} from "../../api/dataAnalysis/analysis";
import { Amap, Marker } from "@amap/amap-vue";

import * as echarts from "echarts";
import { _CarTypeAll } from "../../api/selCar";

export default {
  components: {
    Table,
    Amap,
    AmapMarker: Marker,
  },
  data() {
    return {
      emptyImage: require("../../assets/images/empty.png"),
      ParkOption: [],
      pickerOptions: {
        disabledDate(time) {
          // 禁止选择本日之后
          return time.getTime() > Date.now();
        },
      },
      searchParams: {
        orgIds: [],
        vehicleTypes: "",
        vehicleTypeIds: "",
        vehicleId: "",
        dateRange: [],
        pageNum: 1,
        pageSize: 20,
      },
      timeDefaultShow: [],
      CarTypeOption: [],
      CarTypeOptionParams: {
        children: "children",
        label: "typeName",
        value: "id",
        // checkStrictly: true,
        multiple: true,
      },
      addedmap: null,
      historyListConfig: {
        total: 0,
        loading: false,
        columns: [
          {
            label: "车辆编号",
            prop: "vehicleId",
            width: 130,
          },
          {
            label: "车辆VIN",
            prop: "vin",
            width: 150,
          },
          {
            label: "车辆类型",
            prop: "vehicleType",
            width: 100,
          },
          {
            label: "行驶路线",
            prop: "line",
            width: 120,
          },
          {
            label: "所属园区",
            prop: "parkName",
          },
          {
            label: "行为发生时间",
            prop: "brakeTime",
          },
          {
            label: "触发方式",
            prop: "brakeType",
          },
          {
            label: "操作",
            width: 250,
            fixed: "right",
            render: (h, params) => {
              return h("div", [
                h(
                  "span",
                  {
                    class: "operationA",
                    style: {
                      fontSize: "12px",
                    },
                    on: {
                      click: () => {
                        this.historyDetailConfig.visible = true;
                        this.getlocation(params.row);
                      },
                    },
                  },
                  "查看行为发生位置"
                ),
                h(
                  "span",
                  {
                    class: "operationA",
                    style: {
                      marginLeft: "10px",
                      fontSize: "12px",
                    },
                    on: {
                      click: () => {
                        this.downloadReport(params.row);
                      },
                    },
                  },
                  "下载报文"
                ),
                h(
                  "span",
                  {
                    class: "operationA",
                    style: {
                      marginLeft: "10px",
                      fontSize: "12px",
                    },
                    on: {
                      click: () => {
                        this.downloadVideo(params.row);
                      },
                    },
                  },
                  "下载视频"
                ),
              ]);
            },
          },
        ],
        list: [],
      },

      historyDetailConfig: {
        title: "车辆历史回溯详情",
        visible: false,
        size: "85%",
        total: 0,
        loading: false,
      },

      historyDetailSearchParams: {
        dateRange: [],
      },

      historyDetailData: {
        carNumber: "",
        parkName: "",
        trackList: [],
        carData: [],
        carEvent: {
          total: 0,
          page: 1,
          pageSize: 10,
          loading: false,
          columns: [
            {
              label: "事件名称",
              prop: "eventName",
            },
            {
              label: "开始时间",
              prop: "beginTime",
            },
            {
              label: "结束时间",
              prop: "endTime",
            },
          ],
          list: [],
        },
        carWarning: {
          total: 0,
          page: 1,
          pageSize: 10,
          loading: false,
          columns: [
            {
              label: "告警名称",
              prop: "warningName",
            },
            {
              label: "开始时间",
              prop: "beginTime",
            },
            {
              label: "结束时间",
              prop: "endTime",
            },
            {
              label: "告警级别",
              prop: "warningLevel",
            },
          ],
          list: [],
        },
        v2xData: [],
        carDataChart: [],
      },

      historyCarMovePlayControl: {
        show: true,
        play: false,
        speedButtonDefaultText: "倍速",
        speedButtonText: "倍速",
      },
    };
  },
  mounted() {
    this.InitPageData();

    // this.timeDefaultShow = new Date();
    // this.timeDefaultShow.setMonth(new Date().getMonth() - 1);
    this.initDateRange();
  },
  methods: {
    initDateRange() {
      let pre = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000);
      let today = new Date(new Date().getTime());
      let monthBefore = `${pre.getFullYear()}-${
        pre.getMonth() + 1 < 10 ? `0${pre.getMonth() + 1}` : pre.getMonth() + 1
      }-${pre.getDate() < 10 ? `0${pre.getDate()}` : pre.getDate()}`;
      let monthNow = `${today.getFullYear()}-${
        today.getMonth() + 1 < 10 ? `0${today.getMonth() + 1}` : today.getMonth() + 1
      }-${today.getDate() < 10 ? `0${today.getDate()}` : today.getDate()}`;
      this.searchParams.dateRange = [monthBefore, monthNow];
    },

    async InitPageData() {
      await this.GetParkOption();
      await this.GetPageData();
      await this.GetCarTypeOption();
    },

    getlocation(row) {
      this.$nextTick(() => {
        this.addedmap = new AMap.Map("lookContainer", {
          resizeEnable: true,
          center: [row.longitude, row.latitude],
          zoom: 16,
        });
        let GPS_Position = [row.longitude, row.latitude];

        let that = this;

        AMap.convertFrom(GPS_Position, "gps", function (status, result) {
          if (result.info === "ok") {
            let AmapPosition = [result["locations"][0].lng, result["locations"][0].lat];

            var marker = new AMap.Marker({
              map: that.addedmap,
              clickable: true,
              position: AmapPosition,
              icon: new AMap.Icon({
                image: require("@/assets/images/minbus.png"),
                imageSize: new AMap.Size(36, 36),
                size: new AMap.Size(36, 36),
              }),
              offset: new AMap.Pixel(-13, -26),
            });
          }
        });
      });
    },

    downloadReport(data) {
      _DownloadEmergencyBrakeRecord({ id: data.brakeId }).then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");

        link.style.display = "none";
        link.href = url;
        // link.exportLoad = false;
        link.download = data.vehicleId + "_车辆紧急制动报文.xlsx";

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);

        // this.timer = setTimeout(() => {
        //   this.$message.success("下载成功");
        // }, 3000);
      });
    },

    downloadVideo(row) {
      let carVin = row.vin;
      let alarmTime = row.brakeTime;
      console.log("window.urlConfig.url", window.urlConfig.url);
      window.open(
        `${
          window.urlConfig.url
        }file-nacos/excelDownload/exportCarBrakeVideo?carVin=${carVin}&alarmTime=${alarmTime}&X-Token=${
          "Basic " + window.localStorage.getItem("token")
        }`
      );
      // _downloadEmergencyVideo({carVin: carVin, alarmTime: alarmTime}).then(res => {
      //   console.log('222222', res);
      //   if (res.success) {

      //   } else {
      //     console.log('222222121111111', res);
      //     this.$message.warning('视频下载失败')
      //   }
      //   // const url = window.URL.createObjectURL(res.data);
      //   // const link = document.createElement("a");

      //   // link.style.display = "none";
      //   // link.href = url;
      //   // // link.exportLoad = false;
      //   // link.download = data.vehicleId + "_车辆紧急制动报文.xlsx";

      //   // document.body.appendChild(link);
      //   // link.click();

      //   // document.body.removeChild(link);
      // })

      // var xhr = new XMLHttpRequest();
      // let url = `http://iov.dev.usharingtech.com:32080/api/dev/v1/file-nacos/excelDownload/exportCarBrakeVideo?carVin=${carVin}&alarmTime=${alarmTime}`
      // xhr.open('GET', url, true);
      // //设置请求头参数的方式,如果没有可忽略此行代码
      // xhr.setRequestHeader("Authorization",`Bearer ${window.localStorage.getItem('tokenStr')}`);
      // xhr.setRequestHeader("X-Token",`Basic ${window.localStorage.getItem('token')}`);

      // //设置响应类型为 blob
      // xhr.responseType = 'blob';
      // // 关键部分
      // xhr.onload = function (e) {
      //   // 如果请求执行成功
      //   if (this.status == 200) {
      //     let blob = this.response
      //     let a = document.createElement('a')
      //     // blob.type = "application/octet-stream";
      //     let url = window.URL.createObjectURL(blob)
      //     let fileName = decodeURIComponent(escape(xhr.getResponseHeader('content-disposition').split(';')[1].split('filename=')[1]))
      //     if (window.navigator.msSaveBlob) {
      //       try {
      //         window.navigator.msSaveBlob(blob, fileName)
      //       } catch (e) {
      //         console.log(e)
      //       }
      //     } else {
      //       a.href = url
      //       a.download = fileName
      //       document.body.appendChild(a) // 火狐浏览器 必须把元素插入body中
      //       a.click()
      //       document.body.removeChild(a)
      //       // 释放之前创建的URL对象
      //       window.URL.revokeObjectURL(url)
      //     }
      //   } else { // 失败后需要将blob对象转换为 json 来获取异常信息
      //     const reader = new FileReader();
      //     reader.readAsText(this.response, 'utf-8');
      //     reader.onload = () => {
      //       const { header } = JSON.parse(reader.result);
      //       console.error(header.msg)
      //     }
      //   }
      // }
      // xhr.send()
    },

    //获取车辆类型选项
    async GetCarTypeOption() {
      let res = await _CarTypeAll();

      if (res.success) {
        this.CarTypeOption = res.data.list;
      }
    },

    //获取园区选项
    async GetParkOption() {
      let res = await _getParkList();

      if (res.code === 0) {
        this.ParkOption = res.data;
      }
    },

    handleVehicleTypes() {
      let objVehicleType = this.$refs.cascaderVehicleTypes.getCheckedNodes();
      let arrVehicleType = [];

      for (const vehicleTypeIds of this.searchParams.vehicleTypeIds) {
        for (const objType of objVehicleType) {
          if (vehicleTypeIds[1] === objType.value) {
            arrVehicleType.push(objType.label);
          }
        }
      }

      this.searchParams.vehicleTypes = arrVehicleType;

      this.SearchPageData();
    },

    //region 历史列表
    async GetPageData() {
      this.historyListConfig.loading = true;

      let jsonParams = {
        orgIds: this.searchParams.orgIds,
        vehicleTypes:
          this.searchParams.vehicleTypes === "" ? [] : this.searchParams.vehicleTypes,
        vehicleId: this.searchParams.vehicleId,
        beginDate: this.searchParams.dateRange[0],
        endDate: this.searchParams.dateRange[1],
        pageNum: this.searchParams.pageNum,
        pageSize: this.searchParams.pageSize,
      };

      let res = await _berakDetail(jsonParams);

      if (res.code === 0) {
        this.historyListConfig.list = res.list;
        this.historyListConfig.total = res.total;
      }

      this.historyListConfig.loading = false;
    },

    //分页调用获取历史列表数据
    async GetHistoryListData(pagination) {
      if (pagination) {
        if (pagination.page) {
          this.searchParams.pageNum = pagination.page;
          this.searchParams.pageSize = pagination.limit;
        }
      }

      await this.GetPageData();
    },

    async SearchPageData() {
      this.searchParams.page = 1;
      await this.GetPageData();
    },
    //endregion 历史列表

    //region 历史详情
    async OpenHistoryDetailDrawer(historyData) {
      this.historyDetailSearchParams.dateRange = this.searchParams.dateRange;
      this.historyDetailData.carNumber = historyData.carNumber;
      this.historyDetailData.parkName = historyData.parkName;

      this.historyDetailConfig.visible = true;

      this.GetHistoryDetailData();
    },

    async GetHistoryDetailData() {
      let jsonParams = {
        parkId: "",
        carType: "",
        carNumber: this.historyDetailData.carNumber,
        beginDate: "",
        endDate: "",
      };

      if (this.historyDetailSearchParams.dateRange === null) {
        this.historyDetailSearchParams.dateRange = [];
      }

      if (this.historyDetailSearchParams.dateRange.length === 2) {
        jsonParams.beginDate = this.historyDetailSearchParams.dateRange[0];
        jsonParams.endDate = this.historyDetailSearchParams.dateRange[1];
      }

      let res = await _FindCarHistoryDetail(jsonParams);

      if (res.success !== true) {
        this.$message.warning(res.message);
        return false;
      }

      if (res.data.trackList !== null) {
        this.historyDetailData.trackList = res.data.trackList;

        let nCount = this.historyDetailData.trackList.length;

        for (let i = 0; i < nCount; i++) {
          this.historyDetailData.trackList[i].duration = this.SecConverMinuteSec(
            this.historyDetailData.trackList[i].duration
          );
        }
      }

      if (res.data.carData !== null) {
        this.historyDetailData.carData = res.data.carData;
        this.SetChartData();

        this.$nextTick(() => {
          this.SetChartObject();
        });
      }

      if (res.data.carEvent !== null) {
        this.historyDetailData.carEvent.list = res.data.carEvent.list;
        this.historyDetailData.carEvent.total = res.data.carEvent.total;
      }

      if (res.data.carWarning !== null) {
        this.historyDetailData.carWarning.list = res.data.carWarning.list;
        this.historyDetailData.carWarning.total = res.data.carWarning.total;
      }
    },

    //分页调用获取历史列表数据
    async GetCarEventData(pagination) {
      if (pagination) {
        if (pagination.page) {
          this.historyDetailData.carEvent.page = pagination.page;
          this.historyDetailData.carEvent.pageSize = pagination.limit;
        }
      }

      await this.GetHistoryDetailData();
    },

    SelectedTrack(idx) {
      let objSelectedTrack = this.historyDetailData.trackList[idx];

      if (objSelectedTrack.carMove === null) {
        this.$message.warning(
          "“" + objSelectedTrack.trackName + "” " + "没有可供播放的轨迹数据！"
        );
        return false;
      }
    },

    PlayCarMove() {
      this.historyCarMovePlayControl.play = true;
    },

    PauseCarMove() {
      this.historyCarMovePlayControl.play = false;
    },

    SetCarMoveSpeed(command) {
      let sBtnText = this.historyCarMovePlayControl.speedButtonText;

      if (Number(command) > 1) {
        sBtnText = "x" + command;
      } else {
        sBtnText = "倍速";
      }

      this.historyCarMovePlayControl.speedButtonText = sBtnText;
    },

    SecConverMinuteSec(seconds) {
      let nMinute = 0;
      let nSecond = 0;
      let nSecondTotal = Number(seconds);
      let sResult = "";

      nMinute = parseInt(nSecondTotal / 60);
      nSecond = nSecondTotal % 60;

      if (nSecond === 0) {
        sResult = nMinute + "分";
      } else {
        sResult = nMinute + "分" + nSecond + "秒";
      }

      return sResult;
    },

    //region 图表
    //设置每日上线率图表
    SetChartData() {
      if (this.historyDetailData.carData.length <= 0) return false;

      let nCount = this.historyDetailData.carData.length;
      let Info = null;

      for (let i = 0; i < nCount; i++) {
        Info = this.historyDetailData.carData[i];

        if (Info.chartData.length > 0) {
          let arrDate = [];
          let arrValue = [];

          for (const item of Info.chartData) {
            arrDate.push(item.date);
            arrValue.push(item.val);
          }

          this.historyDetailData.carDataChart.push({
            idx: i,
            title: Info.chartTitle,
            date: arrDate,
            val: arrValue,
          });
        }
      }
    },

    SetChartObject() {
      for (const item of this.historyDetailData.carDataChart) {
        let objChart = echarts.init(document.getElementById("CarDataChart_" + item.idx));
        this.DrawChart(objChart, "#4f84b4", item.title, "", item.date, item.val);
      }
    },

    //绘制图表
    DrawChart(chart, color, text, subtext, dateList, valList) {
      chart.setOption({
        title: {
          text,
          subtext,
          itemGap: 20,
          left: 20,
          top: 20,
          textStyle: {
            color: "#666",
            fontSize: 16,
            fontFamily:
              "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif",
          },
          subtextStyle: {
            color: "#333",
            fontSize: 12,
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: dateList, //x轴：日期
          axisLabel: {
            interval: 0, //X轴信息全部展示
            textStyle: {
              align: "center",
            },
          },
        },
        yAxis: {
          type: "value",
        },
        grid: {
          top: "30%", // 等价于 y: '16%'
          left: "5%",
          right: "8%",
          bottom: "0%",
          containLabel: true,
        },
        series: [
          {
            data: valList, //y轴：值
            type: "line",
            smooth: true,
            symbol: "none",
            itemStyle: {
              normal: {
                lineStyle: {
                  color,
                },
              },
            },
          },
        ],
      });
    },
    //endregion 图表
    //endregion 历史详情
  },
};
</script>

<style lang="less" scoped>
.operationA {
  font-size: 12px;
}

.headDetail {
  display: flex;

  .detailQuery {
    height: 36px;
    background-color: #fff;
    display: flex;
    align-items: center;
    padding: 0px 20px;
    color: #666;
    font-size: 14px;
    margin-right: 30px;

    div:last-child {
      margin-left: 40px;
    }

    span {
      color: #333;
      font-size: 14px;
      font-weight: 550;
      margin-left: 5px;
    }
  }
}

.map-container {
  width: 100%;
  height: 400px;

  .gaode-map {
    z-index: 0;
  }

  .play-control-container {
    width: 100%;
    overflow: hidden;
    position: relative;
    margin-top: -40px;

    .mask {
      width: 100%;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 1;
    }

    .play-control {
      width: 100%;
      height: 40px;
      position: relative;
      margin-top: -40px;

      .play {
        width: 50px;
        height: 40px;
        padding-top: 6px;
        text-align: center;
        float: left;

        i {
          font-size: 30px;
          color: #fff;
          cursor: pointer;
        }
      }

      .speed {
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        float: right;

        .btn-speed {
          font-size: 14px;
          color: #fff;
          cursor: pointer;
        }
      }
    }
  }
}

.track-list-container {
  width: 100%;
  height: 400px;
  overflow-y: auto;

  .list-container-title {
    font-size: 16px;
    color: #666;
    padding: 0;
    margin: 0;
  }

  .list-container-body {
    width: 100%;
    overflow: hidden;
    padding: 0;

    li {
      width: 100%;
      overflow: hidden;
      list-style: none;
      cursor: pointer;

      .icon {
        width: 50px;
        height: 50px;
        line-height: 50px;
        background-color: #999;
        text-align: center;
        font-size: 40px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        float: left;
        color: #e5e5e5;
      }

      .info {
        width: 80%;
        height: 50px;
        float: left;
        margin-left: 10px;

        p {
          padding: 0;
          margin: 0;
        }

        .track-title {
          font-size: 16px;
          color: #333;
          padding-bottom: 3px;
          padding-top: 1px;
        }

        .track-time {
          font-size: 14px;
          color: #333;

          span {
            padding-right: 10px;
          }
        }
      }
    }
  }
}

.car-data-container {
  width: 100%;
  height: 500px;
}

.chart-list-container {
  width: 100%;
  overflow: hidden;

  .chart-item {
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin-bottom: 20px;

    p {
      padding: 0 20px;
      font-size: 16px;
      color: #333;
    }

    .chart {
      width: 100%;
      height: 200px;
    }
  }
}

#lookContainer {
  height: 550px;
}
</style>
